import React from 'react'
import { Router } from '@reach/router'
import { graphql } from 'gatsby'

import ArticleTemplate from '../../templates/ArticleTemplate'

const ArticleRouter = () => {
  return (
    <>
      <Router basepath="/articles">
        <ArticleTemplate path="/:id" />
      </Router>
      <Router basepath="/:lang/articles">
        <ArticleTemplate path="/:id" />
      </Router>
    </>
  )
}

export default ArticleRouter

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "articles"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
