import React from 'react'
import { match, ThemeProvider } from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import styled from 'styled-components'

import LayoutModule from './LayoutModule'

const colorThemes = {
  Black: 'onBlack',
  Blue: 'onPrimary',
  Grey: 'onWhite',
  None: 'onWhite',
}

const flexAlignment = {
  Top: 'flex-start',
  Center: 'center',
  Bottom: 'flex-end',
  Stretch: 'stretch',
}

const indent = {
  Wide: 'lg',
  Narrow: 'md',
  default: 'lg',
}

const COLUMN_GAP = 6
const LayoutTwoColumns = ({
  columnOneCollection,
  columnTwoCollection,
  columnOneWidth = 50,
  columnIndent = 'Wide',
  alignment = 'inherit',
  columnOneColor = 'transparent',
  columnTwoColor = 'transparent',
  columnStyle = 'none',
}) => {
  const columnWidth = columnOneWidth || 50
  const columnChildren = [
    ...columnOneCollection.items,
    ...columnTwoCollection.items,
  ]
  return (
    <SectionContainer
      $hasBackground={
        columnStyle === 'Left Expanded' || columnStyle === 'Right Expanded'
      }
    >
      {columnStyle === 'Left Expanded' && (
        <BackgroundBlock $align="left" $bgColor={columnOneColor} />
      )}
      {columnStyle === 'Right Expanded' && (
        <BackgroundBlock $align="right" $bgColor={columnTwoColor} />
      )}
      <ColumnContainer
        $isExpanded={
          columnStyle === 'Left Expanded' || columnStyle === 'Right Expanded'
        }
        maxWidth={indent[columnIndent] ?? indent['default']}
        $flexDirection={getColumnDirection(columnChildren)}
        $flexAlign={getColumnAlignment({ alignment, columnChildren })}
      >
        <Column
          style={{ '--column-width': `${columnWidth - COLUMN_GAP / 2}%` }}
          $bgColor={columnOneColor}
        >
          <ThemeProvider themeType={colorThemes[columnOneColor]}>
            {columnOneCollection.items.map(({ sys, ...props }) => {
              switch (props.__typename) {
                case 'ImageWithFocalPoint': {
                  return (
                    <LayoutModule
                      key={sys.id}
                      {...props}
                      {...(props.focalPoint.focalPoint && {
                        containerHeight: getContainerHeight(columnChildren),
                      })}
                      width="100%"
                    />
                  )
                }
                case 'LayoutBlockquote': {
                  return (
                    <Box key={sys.id} p={5}>
                      <LayoutModule {...props} $bgColor={columnOneColor} />
                    </Box>
                  )
                }
                default: {
                  return (
                    <Box
                      key={sys.id}
                      py={{ xs: 5, sm: 0 }}
                      px={
                        columnStyle === 'Left Expanded'
                          ? { xs: 3, sm: 0 }
                          : undefined
                      }
                    >
                      <LayoutModule {...props} $bgColor={columnOneColor} />
                    </Box>
                  )
                }
              }
            })}
          </ThemeProvider>
        </Column>
        <Column
          style={{ '--column-width': `${100 - columnWidth - COLUMN_GAP / 2}%` }}
          $bgColor={columnTwoColor}
        >
          <ThemeProvider themeType={colorThemes[columnTwoColor]}>
            {columnTwoCollection.items.map(({ sys, ...props }) => {
              switch (props.__typename) {
                case 'ImageWithFocalPoint': {
                  return (
                    <LayoutModule
                      key={sys.id}
                      {...props}
                      {...(props.focalPoint.focalPoint && {
                        containerHeight: getContainerHeight(columnChildren),
                      })}
                      width="100%"
                    />
                  )
                }
                case 'LayoutBlockquote': {
                  return (
                    <Box key={sys.id} p={5}>
                      <LayoutModule {...props} $bgColor={columnTwoColor} />
                    </Box>
                  )
                }
                default: {
                  return (
                    <Box
                      key={sys.id}
                      py={{ xs: 3, sm: 0 }}
                      px={
                        columnStyle === 'Right Expanded'
                          ? { xs: 3, sm: 0 }
                          : undefined
                      }
                    >
                      <LayoutModule {...props} $bgColor={columnTwoColor} />
                    </Box>
                  )
                }
              }
            })}
          </ThemeProvider>
        </Column>
      </ColumnContainer>
    </SectionContainer>
  )
}

const getColumnDirection = (columnChildren) =>
  !columnChildren.some(itemIsImage)
    ? 'column'
    : itemIsImage(columnChildren[0]) && !itemIsImage(columnChildren[1])
    ? 'column-reverse'
    : 'column'
const getColumnAlignment = ({ columnChildren, alignment }) =>
  columnChildren.some(itemHasFocalPoint)
    ? flexAlignment['Stretch']
    : flexAlignment[alignment]
const getContainerHeight = (columnChildren) =>
  columnChildren.every(itemHasFocalPoint) ? '60vh' : { xs: '30vh', sm: '100%' }
const itemIsImage = (item) => item?.__typename === 'ImageWithFocalPoint'
const itemHasFocalPoint = (item) =>
  itemIsImage(item) && item?.focalPoint.focalPoint
const getBgColor = ({ theme, $bgColor }) => {
  const colors = {
    Black: theme.palette.common.black,
    Blue: theme.palette.primary.main,
    Grey: '#F2F2F2',
    None: 'transparent',
  }
  return colors[$bgColor]
}
const SectionContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin: ${({ $hasBackground }) => ($hasBackground ? 25 : 0)}px 0px;
`

const ColumnContainer = styled(Container)`
  display: flex;
  flex-direction: ${({ $flexDirection }) => $flexDirection};
  justify-content: space-between;
  width: 100%;
  align-items: ${({ $flexAlign }) => $flexAlign};
  position: relative;
  z-index: 1;

  ${match.isSM} {
    padding-top: ${({ $isExpanded }) => ($isExpanded ? '70px' : '0')};
    padding-bottom: ${({ $isExpanded }) => ($isExpanded ? '70px' : '0')};
    flex-direction: row;
  }
`

const Column = styled.div`
  flex: 0 1 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: ${({ theme, $bgColor }) => getBgColor({ theme, $bgColor })};
  ${match.isSM} {
    flex: 0 1 var(--column-width);
    max-width: var(--column-width);
  }
`

const BackgroundBlock = styled.div`
  position: absolute;
  width: 60%;
  height: 100%;
  ${({ $align }) => $align}: 0px;

  ${match.isSM} {
    background: ${({ theme, $bgColor }) => getBgColor({ theme, $bgColor })};
  }
`

export default LayoutTwoColumns
