import React from 'react'
import {
  Heading,
  match,
  P,
  TextLink,
  ThemeProvider,
} from '@klickmarketing/react-components'
import { Box, Container } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { Link as I18nLink, useI18next } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { formatPublishDate } from '../../common/utils'
import ContentfulImageWrapper from '../ContentfulImage/ContentfulImageWrapper'

const ArticleHero = ({ article, collection }) => {
  const { language, t } = useI18next()

  if (!article) {
    return (
      <Box my={10.5}>
        <Skeleton variant="rect" width="100%" height="60vh" />
      </Box>
    )
  }

  const image = article?.heroImage
  const series =
    collection && `${collection.title} ${t('Collection', { ns: 'articles' })}`
  const seriesURL = collection && `/collections/${collection.slug}`
  const authors = article?.personsCollection?.items
  // const length = '5 minute'
  const isContained = article?.heroType === 'Contained'

  return (
    <ThemeProvider themeType="onPrimary">
      <ContentContainer
        position="relative"
        py={10}
        mt={10.5}
        mb={isContained ? '35vh' : 10.5}
        component="header"
        bgcolor={isContained ? 'background.default' : null}
      >
        {!isContained && (
          <BackdropImageContainer>
            <StyledHeroImage
              context="HERO"
              focalPoint={image?.focalPoint?.focalPoint}
              src={image?.image?.url}
              alt={image?.image?.description}
              width={image?.image?.width}
              height={image?.image?.height}
            />
          </BackdropImageContainer>
        )}
        <Container maxWidth="lg">
          {!!collection && (
            <Box textAlign="center">
              <P variant="blurb2">
                <SeriesLink to={seriesURL} component={I18nLink}>
                  {series}
                </SeriesLink>
              </P>
            </Box>
          )}
          <Box textAlign="center" my={8}>
            <Heading variant="h2" component="h1">
              {article.title}
            </Heading>
          </Box>
          {authors?.length ? (
            <Box textAlign="center">
              <Authors variant="blurb2">
                {t('Authored by', { ns: 'articles' })}{' '}
                {authors.map((author, idx) => (
                  <React.Fragment key={author.sys.id}>
                    <Link
                      to={`/discover?people=${author.name}`}
                      component={I18nLink}
                    >
                      {author.name}
                    </Link>
                    {authors.length - 1 !== idx && ', '}
                  </React.Fragment>
                ))}
              </Authors>
            </Box>
          ) : null}
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box textAlign="right">
              <BoldP component="span" variant="blurb2">
                {formatPublishDate(article.publishDate, language)}
              </BoldP>
            </Box>
            {/* TODO: add this back in when we calculate the article length */}
            {/* <Box textAlign="center" width="64px">
              <BoldP component="span" variant="blurb2">
                |
              </BoldP>
            </Box>
            <Box textAlign="left">
              <BoldP component="span" variant="blurb2">
                {length} read
              </BoldP>
            </Box> */}
          </Box>
          {isContained && (
            <Box height="70vh" mb="-35vh" mt={4} position="relative">
              <StyledHeroImage
                context="HERO"
                focalPoint={image?.focalPoint?.focalPoint}
                src={image?.image?.url}
                alt={image?.image?.description}
                width={image?.image?.width}
                height={image?.image?.height}
              />
            </Box>
          )}
        </Container>
      </ContentContainer>
    </ThemeProvider>
  )
}

const BackdropImageContainer = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;

  &::after {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
  }
`

const Authors = styled(P)`
  max-width: 600px;
  margin: 0 auto;
  padding-bottom: 15px;
`

const Link = styled(TextLink)`
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

const SeriesLink = styled(TextLink)`
  text-decoration-color: #fff;
`

const BoldP = styled(P)`
  font-weight: 900;
`

const StyledHeroImage = styled(ContentfulImageWrapper)`
  object-fit: cover;
  /* width: 100%; */
  /* height: 100%; */
`

const ContentContainer = styled(Box)`
  margin-top: 58px;

  ${match.isSM} {
    margin-top: 64px;
  }

  ${match.isMD} {
    margin-top: 84px;
  }
`

export default ArticleHero
