import React from 'react'

import LayoutBlockquote from './LayoutBlockquote'
import LayoutCopy from './LayoutCopy'
import LayoutImage from './LayoutImage'
import LayoutTwoColumns from './LayoutTwoColumns'

const modules = {
  LayoutBlockquote,
  LayoutCopy,
  ImageWithFocalPoint: LayoutImage,
  LayoutTwoColumns,
}

const LayoutModule = ({ __typename, ...props }) => {
  const Module = modules[__typename]
  return <Module {...props} />
}

export default LayoutModule
