import React from 'react'
import {
  Button,
  Decorator,
  HS_EVENTS,
  Link,
  match,
  P,
  tracking,
} from '@klickmarketing/react-components'
import { Box } from '@material-ui/core'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { IDX_FLOODLIGHT_TAGS } from '../../../config'

const ArticleDocuments = ({ documents, documentText, title }) => {
  const { t } = useTranslation()
  return (
    <>
      {documents.map((document) => {
        return (
          <DocumentBox key={document.sys.id} my={8}>
            <StyledBox>
              <DownloadButton
                component={Link}
                href={document.url}
                onClick={() =>
                  tracking.track({
                    action: 'POV Downloads',
                    category: 'Page Interactions',
                    label: title,
                    dcCode: IDX_FLOODLIGHT_TAGS.CLICK_POV_DOWNLOAD_BUTTON,
                    hsPayload: {
                      name: HS_EVENTS.FILE_DOWNLOADED,
                      properties: {
                        hs_file_id: document.sys.id,
                        hs_file_text: title,
                        hs_file_type: document.contentType,
                        hs_file_url: document.url,
                      },
                    },
                  })
                }
                size="xlarge"
              >
                {t('Download Document', { ns: 'articles' })}
              </DownloadButton>
            </StyledBox>
            <StyledContentBox>
              <P variant="body1" fontWeight="bold" color="textSecondary">
                <strong>{title}</strong>
              </P>

              <StyledDecorator $align="left" $size={2} $color="#000" />
              <P
                variant="body2"
                css={`
                  white-space: pre-line;
                `}
                color="textSecondary"
              >
                {documentText
                  ? documentText
                  : t(
                      'We hope you enjoyed reading this POV preview. Make sure to download to view the complete content.',
                      { ns: 'articles' }
                    )}
              </P>
            </StyledContentBox>
          </DocumentBox>
        )
      })}
    </>
  )
}

const StyledDecorator = styled(Decorator)`
  margin: 10px 0px 30px;
  border-top-color: ${({ theme }) => {
    return theme.palette.text.secondary
  }};
`

const DocumentBox = styled(Box)`
  display: flex;
  flex-direction: column-reverse;

  ${match.isSM} {
    flex-direction: row;
    align-items: start;
  }
`

const DownloadButton = styled(Button)`
  width: fit-content;
  padding: 48px 32px;
`

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;

  ${match.isSM} {
    justify-content: center;
  }
`

const StyledContentBox = styled(StyledBox)`
  flex: 1;
  margin-bottom: 16px;
  ${match.isSM} {
    margin-left: 32px;
  }
`

export default ArticleDocuments
