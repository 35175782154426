import React from 'react'
import { P, ThemeProvider } from '@klickmarketing/react-components'
import { useTheme } from '@material-ui/core'
import styled from 'styled-components'

const QuotationMark = ({ rotation = 0, theme }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="37"
    height="30"
    viewBox="0 0 37 30"
    fill="none"
    style={{ transform: `rotate(${rotation}deg)` }}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36.0116 14.8705L26.3116 14.8705C26.3547 12.3738 27.3889 9.99042 29.1974 8.22021C31.0059 6.44999 33.448 5.43055 36.0116 5.37577L36.0116 0.000244141C31.9826 0.0555706 28.1351 1.64109 25.289 4.41883C22.4429 7.19656 20.8234 10.9469 20.7756 14.8705V29.6729L36.0156 29.6729V14.8705H36.0116ZM16.1016 29.6729L16.1016 14.8705L6.40161 14.8705C6.44468 12.3738 7.47894 9.99042 9.28741 8.22021C11.0959 6.44999 13.5381 5.43055 16.1016 5.37577V0.000244141C12.0725 0.0553246 8.22483 1.6408 5.37872 4.41859C2.53262 7.19638 0.913181 10.9468 0.865601 14.8705V29.6729H16.1056H16.1016Z"
      fill={theme.palette.text.primary}
    />
  </svg>
)

const LayoutBlockQuote = ({ $bgColor = 'None', content }) => {
  // TODO convert this to use our theme "onWhite, onBlue ect."
  const theme = useTheme()
  const themeLookup = {
    Black: 'onBlack',
    Blue: 'onPrimary',
    Grey: 'onWhite',
    None: 'onWhite',
  }
  return (
    <ThemeProvider themeType={themeLookup[$bgColor]}>
      <BlockContainer>
        <QuoteBreak>
          <QuoteLine />
          <QuoteTick>
            <QuotationMark theme={theme} rotation={0} />
          </QuoteTick>
          <QuoteLine />
        </QuoteBreak>
        <QuoteContent variant="h5">{content}</QuoteContent>
        <QuoteBreak>
          <QuoteLine />
          <QuoteTick>
            <QuotationMark theme={theme} rotation={180} />
          </QuoteTick>
          <QuoteLine />
        </QuoteBreak>
      </BlockContainer>
    </ThemeProvider>
  )
}

const BlockContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 32px 0px 32px;
`

const QuoteBreak = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const QuoteTick = styled.div`
  margin: 0px 32px;
`

const QuoteLine = styled.div`
  flex: 1;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 6px;
    position: absolute;
    top: calc(50% - 3px);
    left: 0px;
    background: ${({ theme }) => theme.palette.text.primary};
  }
`

const QuoteContent = styled(P)`
  display: block;
  padding: 48px 32px;
  margin: 0px;
  text-align: center;
  color: ${({ theme }) => theme.palette.text.primary};
`

export default LayoutBlockQuote
