import React from 'react'
import { gql } from '@apollo/client'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Button,
  Heading,
  isBrowser,
  match,
  P,
  Section,
  ThemeProvider,
  tracking,
} from '@klickmarketing/react-components'
import { Box, Container, Grid } from '@material-ui/core'
import { navigate } from 'gatsby'
import {
  Link as I18nLink,
  useI18next,
  useTranslation,
} from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import { IDX_FLOODLIGHT_TAGS } from '../../config'
import { DEFAULT_LOCALE, LOCALES } from '../../languages'
import { formatPublishDate, getSocialThumbPath } from '../common/utils'
import ArticleDocuments from '../components/_articles/ArticleDocuments'
import ArticleHero from '../components/_articles/ArticleHero'
import ArticleModules from '../components/_articles/ArticleModules'
import AuthorCard from '../components/_articles/AuthorCard'
import ContactUs from '../components/ContactUs/ContactUs'
import Layout from '../components/Layout/Layout'
import MediaTile from '../components/MediaTile/MediaTile'
import TagList from '../components/TagList/TagList'
import { useQueryLatestData } from '../hooks/useQueryLatestData'

const ArticleTemplate = ({ id }) => {
  const { language, t } = useI18next()
  const { isLoading: isAuthLoading } = useAuth0()
  const { latestData, loading, error } = useQueryLatestData(GET_ARTICLE_DATA, {
    variables: {
      id,
      locale: language || DEFAULT_LOCALE,
      allLocales: Object.values(LOCALES),
    },
  })

  React.useEffect(() => {
    tracking.track({ dcCode: IDX_FLOODLIGHT_TAGS.PAGE_LOAD_ARTICLE })
  }, [])

  React.useEffect(() => {
    if (!error) return
    navigate('/404', { replace: true })
  }, [error])

  React.useEffect(() => {
    if (!latestData) return
    // if there are no results retrieved send to a 404 page
    if (latestData.articlePostCollection.items.length === 0) {
      window.location = '/404'
    }
  }, [latestData])

  const article = latestData?.articlePostCollection?.items?.[0]
  const socialThumbPath = getSocialThumbPath(
    article,
    article?.publishDate
      ? {
          d: formatPublishDate(article.publishDate, language),
        }
      : {}
  )

  const seoProps = !article
    ? { title: t(`Articles`, { ns: 'articles' }) }
    : {
        title: article.title,
        description: article.description,
        type: 'article',
        socialThumbPath,
      }

  return (
    <Layout seoProps={seoProps}>
      <Box minHeight="100vh">
        <ArticleContent
          data={latestData}
          isAuthLoading={isAuthLoading}
          isContentFetching={loading}
        />
      </Box>
    </Layout>
  )
}

const ArticleContent = ({ data, isAuthLoading, isContentFetching }) => {
  const { loginWithRedirect } = useAuth0()
  const article = data?.articlePostCollection?.items?.[0]
  const collection = article?.linkedFrom?.collectionCollection.items?.[0]
  const authors = article?.personsCollection?.items || []
  const articleModules = article?.contentModulesCollection?.items || []
  const documents = article?.documentsCollection?.items || []
  const documentText = article?.documentText
  const tags = article?.tagsCollection?.items || []
  const relatedMedia = article?.relatedMediaCollection.items.slice(0, 3) || []
  const { t } = useTranslation()

  const AuthOrContentLoading = isAuthLoading || isContentFetching
  const isAllowedToView = !AuthOrContentLoading && articleModules.length > 0

  const returnTo = isBrowser && window.location.pathname
  const loginOnClick = () =>
    loginWithRedirect({
      appState: {
        returnTo,
      },
    })

  return (
    <div>
      <ArticleHero article={article} collection={collection} />
      {AuthOrContentLoading || isAllowedToView ? (
        <ArticleModules
          context="article"
          articleModules={articleModules}
          loading={AuthOrContentLoading}
        />
      ) : (
        <>
          <Box
            sx={{
              textAlign: 'center',
              pb: 10,
              display: { xs: 'none', sm: 'block' },
            }}
          >
            <Button onClick={loginOnClick} size="xxlarge">
              Sign Up or Log in to View
            </Button>
          </Box>
          <Box
            sx={{
              textAlign: 'center',
              pb: 10,
              display: { xs: 'block', sm: 'none' },
            }}
          >
            <Button onClick={loginOnClick} size="large">
              Sign Up or Log in to View
            </Button>
          </Box>
        </>
      )}
      {!!authors.length || !!tags.length ? (
        <Container maxWidth="md">
          {!AuthOrContentLoading && !!documents?.length ? (
            <ArticleDocuments
              documents={documents}
              title={article.title}
              documentText={documentText}
            />
          ) : null}
          {!!tags.length && (
            <Box my={2} mb={8}>
              <P variant="blurb2">
                <b>{t('Tags', { ns: 'articles' })}</b>
              </P>
              <TagList tags={tags} />
            </Box>
          )}
          <hr />
          <Box my={8}>
            <Box mb={4}>
              <P variant="blurb2">
                <b>
                  {authors.length === 1
                    ? t('Author', { ns: 'articles' })
                    : t('Authors', { ns: 'articles' })}
                </b>
              </P>
            </Box>
            {authors.map((author, idx) => (
              <React.Fragment key={author.sys.id}>
                <AuthorCard author={author} />
                {idx !== authors.length - 1 && (
                  <AuthorDividerContainer>
                    <AuthorDividerOffset />
                    <AuthorDividerContent>
                      <hr />
                    </AuthorDividerContent>
                  </AuthorDividerContainer>
                )}
              </React.Fragment>
            ))}
          </Box>
        </Container>
      ) : null}
      <ThemeProvider themeType="onBlack">
        <Section name="Related Posts">
          <Container maxWidth="lg">
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Heading variant="h4" component="h2">
                  {t('Related Content', { ns: 'articles' })}
                </Heading>
              </Grid>
              {isContentFetching
                ? Array.apply(null, new Array(3)).map((o, i) => (
                    <Grid item key={i} xs={12} md={4}>
                      <MediaTile isLoading={true} />
                    </Grid>
                  ))
                : relatedMedia?.map((item, i) => (
                    <Grid item key={i} xs={12} md={4}>
                      <MediaTile {...item} context="MEDIA_TILE_RELATED" />
                    </Grid>
                  ))}
            </Grid>
            {collection && (
              <Box textAlign="center" pt={12}>
                <Button
                  component={I18nLink}
                  size="large"
                  to={`/collections/${collection.slug}`}
                >
                  {`View more from the ${collection.title} Collection`}
                </Button>
              </Box>
            )}
          </Container>
        </Section>
        <ContactUs />
      </ThemeProvider>
    </div>
  )
}

const AuthorDividerContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${match.isSM} {
    flex-direction: row;
  }
`

const AuthorDividerOffset = styled.div`
  width: 130px;
`

const AuthorDividerContent = styled.div`
  flex: 1;
  margin: 32px;
  max-width: 500px;
`

const GET_ARTICLE_DATA = gql`
  query GetArticleData(
    $id: String!
    $locale: String!
    $preview: Boolean
    $allLocales: [String]!
  ) {
    articlePostCollection(
      preview: $preview
      where: { slug: $id }
      limit: 1
      locale: $locale
    ) {
      items {
        restrictToRoles
        title
        publishDate
        description
        heroType
        heroImage {
          focalPoint
          image {
            url
            description
            width
            height
          }
        }
        relatedMediaCollection {
          items {
            __typename
            ... on VideoPost {
              title
              slug
              videoAsset {
                duration
                thumbnail {
                  url
                }
              }
              linkedFrom(allowedLocales: $allLocales) {
                collectionCollection(limit: 10) {
                  items {
                    title
                  }
                }
                eventCollection(limit: 10) {
                  items {
                    title
                  }
                }
              }
              personsCollection(limit: 20) {
                items {
                  name
                }
              }
              tagsCollection(limit: 40) {
                items {
                  name
                }
              }
            }
            ... on ArticlePost {
              title
              slug
              heroImage {
                focalPoint
                image {
                  url
                  description
                  width
                  height
                }
              }
              linkedFrom(allowedLocales: $allLocales) {
                collectionCollection(limit: 10) {
                  items {
                    title
                  }
                }
                eventCollection(limit: 10) {
                  items {
                    title
                  }
                }
              }
              personsCollection(limit: 20) {
                items {
                  name
                }
              }
              tagsCollection(limit: 40) {
                items {
                  name
                }
              }
            }
          }
        }
        tagsCollection {
          items {
            name
            sys {
              id
            }
          }
        }
        linkedFrom(allowedLocales: $allLocales) {
          collectionCollection(limit: 1) {
            items {
              title
              description
              slug
            }
          }
        }
        personsCollection(limit: 15) {
          items {
            sys {
              id
            }
            name
            credentials
            title
            company
            shortBio
            image {
              url
            }
          }
        }
        contentModulesCollection(limit: 500) {
          items {
            __typename
            ... on LayoutBlockquote {
              sys {
                id
              }
              content
            }
            ... on ImageWithFocalPoint {
              sys {
                id
              }
              image {
                url
                description
                width
                height
              }
              focalPoint
              caption
              indent
              zoom
            }
            ... on LayoutCopy {
              sys {
                id
              }
              content
            }
            ... on LayoutTwoColumns {
              sys {
                id
              }
              alignment
              columnStyle
              columnIndent
              columnOneWidth
              columnOneColor
              columnOneCollection(limit: 1) {
                items {
                  __typename
                  ... on LayoutBlockquote {
                    sys {
                      id
                    }
                    content
                  }
                  ... on LayoutCopy {
                    sys {
                      id
                    }
                    content
                  }
                  ... on ImageWithFocalPoint {
                    sys {
                      id
                    }
                    caption
                    focalPoint
                    indent
                    zoom
                    image {
                      url
                      description
                      width
                      height
                    }
                  }
                }
              }
              columnTwoColor
              columnTwoCollection(limit: 1) {
                items {
                  __typename
                  ... on LayoutBlockquote {
                    sys {
                      id
                    }
                    content
                  }
                  ... on LayoutCopy {
                    sys {
                      id
                    }
                    content
                  }
                  ... on ImageWithFocalPoint {
                    sys {
                      id
                    }
                    caption
                    focalPoint
                    indent
                    zoom
                    image {
                      url
                      description
                      width
                      height
                    }
                  }
                }
              }
            }
          }
        }
        documentsCollection(limit: 4) {
          items {
            sys {
              id
            }
            title
            description
            contentType
            url
          }
        }
        documentText
      }
    }
  }
`

export default ArticleTemplate
