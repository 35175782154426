import React from 'react'
import { match, P, TextLink } from '@klickmarketing/react-components'
import { ButtonBase } from '@material-ui/core'
import { Link as I18nLink } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

import anonymousImg from '../../assets/images/Persons/anonymous.png'

const AuthorCard = ({ author, ...rest }) => {
  const imageUrl = author?.image?.url || anonymousImg

  const name = author?.credentials?.length
    ? `${author.name}, ${author.credentials.join(', ')}`
    : author.name
  const authorlink = `/discover?people=${author.name}`
  return (
    <ContentBox>
      <ImageBox component={I18nLink} to={authorlink}>
        <img
          srcSet={`${imageUrl}?w=130, ${imageUrl}?w=260 2x`}
          src={`${imageUrl}?w=130`}
          alt={name}
        />
      </ImageBox>
      <StyledContentBox>
        <P variant="body1">
          <NameBox>
            <StyledTextLink component={I18nLink} to={authorlink}>
              {name}
            </StyledTextLink>
            <br />
            {author?.title}
          </NameBox>
        </P>
        <P variant="body1">{author.shortBio}</P>
      </StyledContentBox>
    </ContentBox>
  )
}

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${match.isSM} {
    flex-direction: row;
    align-items: unset;
    text-align: left;
  }
`

const ImageBox = styled(ButtonBase)`
  height: 130px;
  width: 130px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 32px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${match.isSM} {
    margin-bottom: 0px;
  }
`

const StyledContentBox = styled.div`
  flex: 1;
  max-width: 500px;

  ${match.isSM} {
    margin-left: 32px;
  }
`

const NameBox = styled.span`
  margin-bottom: 32px;

  ${match.isSM} {
    margin: 0px;
  }
`

const StyledTextLink = styled(TextLink)`
  font-weight: 900;
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`

export default AuthorCard
